<template>
<div>
  <el-container id="app">
    <el-main>

      <div class="header-logo">
        <img src="./assets/header-logo.png" style="width: 100%; display: block;" />
        <div class="button-sync">
          <el-link @click="changeWallet" :underline="false"><img src="./assets/button-sync.png" style="width: 84px;"/></el-link>
        </div>
        <div class="button-links">
          <el-link href="#mint" :underline="false"><img src="./assets/button-mint2.png" style="width: 175px;"/></el-link>
          <el-link href="#about" :underline="false"><img src="./assets/button-about.png" style="width: 175px;"/></el-link>
        </div>
      </div>
      <div class="story">
        <p>In 2420, after the collapse of the global ecosystem, due to the excessive minting of NFTs on proof of work blockchains, only 6666 kids survived.</p>
        <h2>And together they formed the Cyberkidz Club</h2>
      </div>
      <div class="picture-day-sign">
        <img src="./assets/picture-day-sign.png" style="width: 100%; display: block;" />
      </div>
      <div class="notice">
        <p>Kidz, it is very important that you get ready for a very special day! Soon, you will have your pictures taken for your club ids!</p>
        <p>Don’t forget to sign up through your personal clubpads!</p>
      </div>
      <div class="picture-day-mint" id="mint">
        <img src="./assets/picture-day-mint.png" style="width: 100%; display: block;" />
        <div class="mint active-minting" v-if="isMinting && numMinted < maxMint" v-loading="mintOpPending" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-slider
            v-model="mintNum"
            :step="1"
            :min="1"
            :max="10"
            :show-tooltip="false"
            @input="updateMintNumAsText">
          </el-slider>
          <p style="margin-top: 8px; margin-bottom: 8px;">I want to mint: <span style="color: #7EF45D;">{{ mintNumAsText }}</span></p>
          <el-link @click="mint" :underline="false"><img src="./assets/button-mint.png" style="width: 120px;"/></el-link>
          <p style="margin-top: 8px; margin-bottom: 0;">{{numMinted}} / {{maxMint}} minted</p>
        </div>
        <div class="mint" v-else-if="isMinting" style="font-size: 2em;">
          <H2 style="margin: 0; font-size: 4em;">SOLD OUT</H2>
          <p style="margin-top: 0; margin-bottom: 8px;">{{numMinted}} / {{maxMint}} minted</p>
          <a href="https://objkt.com/collection/cyberkidzclub" style="color: #fff;">View collection on Objkt.com<i class="far fa-external-link"></i></a>
        </div>
        <div class="mint" v-else>
          Minting soon!
        </div>
      </div>

      <div class="team">
        <h2>The Team</h2>

        <el-row>
          <el-col :span="12" style="text-align: center;">
            <img src="./assets/team-stickhen.png" style="width: 70%; vertical-align: bottom;" />
          </el-col>
          <el-col :span="12" style="text-align: center;">
            <img src="./assets/team-crunchy.png" style="width: 70%; vertical-align: bottom;" />
          </el-col>
        </el-row>
        <el-row class="team-name">
          <el-col :span="12">
            <h3>Stickhen</h3>
          </el-col>
          <el-col :span="12">
            <h3>Crunchy</h3>
          </el-col>
        </el-row>
        <el-row class="team-info">
          <el-col :span="12">
            <a href="https://twitter.com/stickhen_">@stickhen_</a>
            stickhen is a series of 200 1/1
            handmade characters made by
            a collective group of brazilian artists
            caio vaz, john casablancas and leo cotello.
            Stickhen acts as a dao since all holders
            participate in every major project decision.
          </el-col>
          <el-col :span="12">
            <a href="https://twitter.com/crunchytez">@crunchytez</a>
            Crunchy provides defi services
            and solutions on tezos to projects,
            developers, and end users. 
            crunchy is maintained by 
            independent developers
            and is governed by crdao holders
          </el-col>
        </el-row>
      </div>


      <div class="faq" id="about">
        <el-row :gutter="30" type="flex" align="bottom">
          <el-col :span="24">
            <h1 style="margin: 0; text-align: center">Tell me more!</h1>
          </el-col>
        </el-row>
        <el-row :gutter="100" style="margin-top: 50px;">
          <el-col :xs="24" :md="12">
            <h2>What is the mint price?</h2>
            <p>Our low minting price of 3 xtz ensures any artist or collector can get a cyberkidz.</p>
            <h2>How many can I mint?</h2>
            <p>Whitelisted wallets will be allowed to mint before the public sale. There is no max minting cap per wallet.</p>
          </el-col>
          <el-col :xs="24" :md="12" style="text-align: center">
            <img src="./assets/cyberkid-1.png" style="width: 75%; max-width: 500px;" />
          </el-col>
        </el-row>
        <el-row :gutter="100" style="margin-top: 50px;">
          <el-col :xs="24" :md="12" style="text-align: center">
            <img src="./assets/cyberkid-2.png" style="width: 75%; max-width: 500px;" />
          </el-col>
          <el-col :xs="24" :md="12">
            <h2>How do I get whitelisted?</h2>
            <p>All holders of stickhen will get whitelisted. All holders of a cyberkidz collab tribute will get whitelisted. More whitelist spots will be available through raffles in our discord.</p>
          </el-col>
        </el-row>
        <el-row :gutter="100" style="margin-top: 50px;">
          <el-col :xs="24" :md="12">
            <h2>Where can I trade my cyberkidz?</h2>
            <p>The cyberkidz club fa2 tokens will have their own collection on objkt.com and can be traded there.</p>
            <h2>How many cyberkidz are there?</h2>
            <p>There will be a total of 6666 unique cyberkidz, with over 450 traits in common, uncommon, rare and super rare rarities, including 10 mythic cyberkidz.</p>
          </el-col>
          <el-col :xs="24" :md="12" style="text-align: center">
            <img src="./assets/cyberkid-3.png" style="width: 75%; max-width: 500px;" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 50px;">
          <el-col :xs="24" :md="{span: 14, offset: 5}" style="background: rgba(0,0,0,0.05); border-radius: 10px; padding: 12px;">
            <h2>Provenance &amp; Contracts</h2>
            <p v-if="crowdSaleContract">Crowdsale Contract:<br/><a :href="`https://better-call.dev/mainnet/${crowdSaleContract}/operations`">{{ crowdSaleContract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
            <p v-else>Crowdsale Contract: <strong>Coming Soon!</strong></p>
            <p v-if="fa2Contract">Cyberkidz NFT Contract:<br/><a :href="`https://better-call.dev/mainnet/${fa2Contract}/operations`">{{ fa2Contract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
            <p v-else>Cyberkidz NFT Contract: <strong>Coming Soon!</strong></p>
            <p v-if="provenanceHash">Provenance Hash:<br/><strong>{{ provenanceHash }}</strong></p>
            <p v-else>Provenance Hash: <strong>Coming Soon!</strong></p>
          </el-col>
        </el-row>
      </div>

      <div class="roadmap" style="color: #9CC9D7;">
        <h2>Roadmap</h2>
        <p>10% of all revenue generated from the mint will be used towards the creation of an art gallery.</p>
        <p>We will spend 200XTZ every week for 10 weeks on art created and voted on by our holders.</p>
        <p>50% of all royalties will be allocated to expand the gallery we will build with our community.</p>
        <p>further down, we will curate a virtual gallery to showcase all the art we have collected in a metaverse.</p>
        <p>cyberkidz holders will be able to access an exclusive merch shop and participate in awesome giveaways!</p>
      </div>

      <el-row :gutter="0" type="flex" align="middle" style="padding: 32px 0;">
        <el-col :span="12" style="font-size: 72px; text-align: center;">
          <a :href="twitterUrl" style="color: #fff;"><i class="fab fa-twitter"></i></a>
        </el-col>
        <el-col :span="12" style="font-size: 72px; text-align: center;">
          <a :href="discordUrl" style="color: #fff;"><i class="fab fa-discord"></i></a>
        </el-col>
      </el-row>

    </el-main>
  </el-container>

</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './utils/tezos';
import tzkt from './utils/tzkt';

export default {
  name: 'app',
  data() {
    return {
      discordUrl: "https://discord.gg/Kn9huDzeyh",
      twitterUrl: "https://twitter.com/cyberkidzclub",
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      saleStartTime: new Date('2021-11-26T14:00:00Z').getTime(),
      numMinted: 0,
      maxMint: 6666,
      mintOpPending: false,
      mintNum: 6,
      mintNumAsText: "five"
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isMinting: function() {
      return (new Date() >= new Date('2021-11-26T14:00:00Z'));
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    updateMintNumAsText() {
        switch (this.mintNum) {
            case 1: this.mintNumAsText = "one"; break;
            case 2: this.mintNumAsText = "two"; break;
            case 3: this.mintNumAsText = "three"; break;
            case 4: this.mintNumAsText = "four"; break;
            case 5: this.mintNumAsText = "five"; break;
            case 6: this.mintNumAsText = "six"; break;
            case 7: this.mintNumAsText = "seven"; break;
            case 8: this.mintNumAsText = "eight"; break;
            case 9: this.mintNumAsText = "nine"; break;
            case 10: this.mintNumAsText = "ten"; break;
        }
    },

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < 6666) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint() {
      const amount = parseInt(this.mintNum * 3000000);
      if (amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(this.mintNum)
          .send({
            amount: amount,
            mutez: true,
            fee: 55000,
            gasLimit: this.mintNum <= 8 ? 250000 : 350000,
            storageLimit: 3000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style scss>
@font-face {
  font-family: "PalmerLakePrint-Regular";
  src: url('assets/fonts/PalmerLakePrintRegular/font.woff2') format('woff2'), url('assets/fonts/PalmerLakePrintRegular/font.woff') format('woff');
}

html,
body {
  margin: 0;
  height: 100%;
  background: #a9edff url('assets/background.png');
}

body {
  font-family: 'PalmerLakePrint-Regular', sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.50);
}

#app {
  background: #9cc9d7;
  max-width: 1280px;
  margin: 0 auto;
}

#app .el-main {
  padding: 0;
}

.notice {
  padding: 32px 200px;
  text-align: center;
}

h1 {
  font-weight: bold;
  font-size: 76px;
  margin-bottom: 32px;
  line-height: 66px;
}

p {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 24px;
}

p a {
  color: #fff;
}


.header-logo {
  position: relative;
}
.button-sync {
  position: absolute;
  top: 64px;
  right: 32px;
}

.button-links {
  position: absolute;
  right: 32px;
  top: 28%;
  width: 200px;
  text-align: right;
}
.button-links a {
    margin-bottom: 16px;
}

.story {
  position: relative;
  z-index: 999;
  color: #9CC9D7;
  border: 1px solid #aa5a4f;
  background: #aa5a4f;
  text-align: center;
  padding: 0 150px;
  margin: 0;
  margin-top: -150px;
}
.story p {
  font-size: 38px;
}
.story h2 {
  font-size: 56px;
}


.picture-day-mint {
    position: relative;
}
.mint {
    position: absolute;
    bottom: 20%;
    width: 100%;
    text-align: center;
    font-size: 4.5em;
}

.team, .roadmap {
  background: #EC6867;
  padding-top: 25px;
}

.team h2, .roadmap h2 {
    font-size: 64px;
    font-weight: bold;
    text-align: center;
    color: #9CC9D7;
    margin: 0;
}

.team .team-name {
  background: #9CC9D7;
  padding: 18px 0;
}
.team .team-name h3 {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
}

.team-info, .roadmap p {
    text-align: center;
    color: #9CC9D7;
    font-size: 32px;
}
.team-info .el-col {
    padding: 18px 36px;
}
.team-info a {
    display: block;
    font-size: 36px;
    font-weight: bold;
    color: #9CC9D7;
    text-decoration: none;
    margin-bottom: 12px;
}
.team-info a:hover {
    text-decoration: none;
    color: #fff;
}

.faq {
    padding: 100px;
}
.faq h2 {
  font-size: 54px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 40px;
  color: #F24C4A;
}
.faq p {
  font-size: 36px;
}
.faq strong {
  color: #fff;
}
.faq p + h2 {
  margin-top: 80px;
}

.roadmap {
    padding-bottom: 50px;
}
.roadmap p {
  font-size: 36px;
}



#app .el-slider {
    width: 45%;
    margin: auto;
}
#app .el-slider__runway {
    height: 20px;
    background-color: #EC6867;
    border-radius: 10px;
}
#app .el-slider__bar {
    height: 20px;
    background-color: #7EF45D;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
#app .el-slider__button-wrapper {
    top: -42px;
}
#app .el-slider__button {
    border: 3px solid #fff;
    background-color: #7EF45D;
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.90);
}









.el-loading-mask {
  border-radius: 12px;
}


#countdown {
  background: #F5831E;
  color: #fff;
  padding: 12px;
  font-size: 18px;
  text-align: center;
}

#countdown span {
  display: block;
  width: 100%;
}

#countdown span span {
  font-size: 32px;
  margin: 18px 0 12px 0;
}

#countdown a {
  color: #fff;
  text-decoration: none;
}
#countdown a:hover {
  text-decoration: underline;
}
#countdown a i {
  margin-left: 12px;
}





.el-avatar {
    background: transparent;
}

@media only screen and (max-width: 991px) {
  .notice {
    padding: 32px;
  }
  .faq {
    padding: 32px;
    padding-bottom: 0px;
  }
  .roadmap {
    padding: 32px;
  }
  .story {
    margin-top: -100px;
    padding: 0 32px;
  }
  .active-minting img {
    width: 75px !important;
  }
  .mint {
      font-size: 16px;
  }
  #app .el-slider__button-wrapper {
      top: -8px;
  }
}

</style>
